.LoginPage_row {
  margin-left: 0;
  margin-right: 0;
}
.LoginPage_links {
  font-weight: bold;
  color: rgb(63, 72, 204);
}
.LoginPage_btn {
  background-color: rgb(63, 72, 204);
  border: 0;
  font-weight: bold;
  border-radius: 25px;
  color: white;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 7px;
  padding-top: 7px;
}
.LoginPage_headerText {
  font-weight: bold;
}
.LoginPage_box {
  background-color: rgb(249, 249, 255);
  padding: 50px;
}
.Login_image {
  height: 100%;
}
/* Small Devices */
@media (min-width: 576px) and (max-width: 767px) {
  .LoginPage_btn {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 7px;
    padding-top: 7px;
  }
  .LoginPage_box {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }
}
/* Small Devices */
@media (max-width: 576px) {
  .LoginPage_btn {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 7px;
    padding-top: 7px;
  }
  .LoginPage_box {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }
}
