.AboutUs_row {
  margin-left: 0;
  margin-right: 0;
  margin-top: 40px;
}

.AboutUs_headerText {
  color: rgb(12, 12, 98);
  font-weight: bold;
  font-size: 28px;
}
.AboutUs_headerText:after {
  content: "";
  height: 3px;
  width: 10%;
  background-color: rgb(12, 12, 98);
  position: absolute;
  top: 3rem;
  left: 50%;
  transform: translate(-50%);
}

.AboutUs_subheaderText {
  margin-top: 30px;
  margin-left: 90px;
  margin-right: 90px;
}
.AboutUs_personImg {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.AboutUs_textMargins {
  margin-left: 190px;
  margin-right: 190px;
}
.AboutUs_links {
  text-decoration: none;
}
.AboutUs_links:hover {
  text-decoration: underline;
  text-decoration-color: black;
}

/* ////////////////////////// */

/*  Small devices */
@media (max-width: 576px) {
  .AboutUs_row {
    margin-top: 30px;
  }
  .AboutUs_headerText {
    color: rgb(12, 12, 98);
    font-weight: bold;
    font-size: 20px;
  }
  .AboutUs_headerText:after {
    content: "";
    height: 3px;
    width: 20%;
    background-color: rgb(12, 12, 98);
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translate(-50%);
  }

  .AboutUs_subheaderText {
    margin-top: 20px;
    margin-left: 0px;
    font-size: 12px;
    margin-right: 0px;
  }
  .AboutUs_personImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .AboutUs_textMargins {
    margin-left: 0px;
    font-size: 12px;
    margin-right: 0px;
  }
}
/*  Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
  .AboutUs_row {
    margin-top: 30px;
  }
  .AboutUs_headerText {
    color: rgb(12, 12, 98);
    font-weight: bold;
    font-size: 20px;
  }
  .AboutUs_headerText:after {
    content: "";
    height: 3px;
    width: 20%;
    background-color: rgb(12, 12, 98);
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translate(-50%);
  }

  .AboutUs_subheaderText {
    margin-top: 20px;
    margin-left: 0px;
    font-size: 12px;
    margin-right: 0px;
  }
  .AboutUs_personImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .AboutUs_textMargins {
    margin-left: 0px;
    font-size: 12px;
    margin-right: 0px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1024px) {
  .AboutUs_subheaderText {
    margin-top: 20px;
    margin-left: 0px;
    font-size: 12px;
    margin-right: 0px;
  }
  .AboutUs_textMargins {
    margin-left: 0px;
    font-size: 12px;
    margin-right: 0px;
  }
}
/* Large devices (desktops, and up) */
@media (min-width: 1025px) and (max-width: 1200px) {
}
