.Dashboard_row {
  margin-left: 0;
  margin-right: 0;
}
.Dashboard_Page {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 150px;
}
.Dashboard_table {
  border: 1px solid black;
}
.DashboardPage_links {
  color: black;
  font-weight: bold;
}

.DashboardPage_links:hover {
  color: black;
  font-weight: bold;
}
.Dashboard_footerText {
  color: white;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  margin-left: 10px;
  width: 30%;
}
.Dashboard_colBorder {
  border: 1px solid black;
}
/* Small Devices */
@media (min-width: 576px) and (max-width: 767px) {
  .Dashboard_footerText {
    width: 90%;
    font-size: 12px;
  }
}
/* Small Devices */
@media (max-width: 576px) {
  .Dashboard_footerText {
    width: 90%;
    font-size: 12px;
  }
}
