body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html {
  scroll-behavior: smooth;
}

.Form_video {
  width: 560px;
  height: 315px;
}
.stepper1 {
  width: 100%;
  padding-top: 20px;
}
.stepper2 {
  width: 100%;
  /* padding-top: 20px; */
}
.VideoLink:hover {
  text-decoration: underline;
  cursor: pointer;
}
.resultSpinner {
  width: 3.5rem !important;
  height: 3.5rem !important;
  color: rgb(47, 85, 151) !important;
}
/*  Small devices */
@media (max-width: 576px) {
  .Form_video {
    margin-top: 20px;
    width: 300px;
    height: 200px;
  }
  .Form_textPara {
    font-size: 12px;
  }
  .stepper1 {
    padding-top: 0;
  }
  .tableText {
    font-size: 13px;
  }
  .mobileText {
    display: block;
  }
  .notfoundText {
    font-size: 25px !important;
  }
}
/*  Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
  .notfoundText {
    font-size: 25px !important;
  }
  .stepper1 {
    padding-top: 0;
  }
  .Form_video {
    margin-top: 20px;
    width: 300px;
    height: 200px;
  }
  .tableText {
    font-size: 13px;
  }
  .Form_textPara {
    font-size: 12px;
  }
  .mobileText {
    display: none;
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1024px) {
  .stepper1 {
    height: 230px;
    width: 100%;
    padding-top: 0px;
  }
  .Form_video {
    width: 300px;
    height: 200px;
  }
  .Form_textPara {
    font-size: 14px;
    padding-left: 0 !important;
    margin-left: 0;
  }
  .mobileText {
    display: none;
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 1025px) and (max-width: 1200px) {
  .stepper1 {
    height: 250px;
    width: 100%;
    padding-top: 20px;
  }
  .mobileText {
    display: none;
  }
}
@media (min-width: 1200px) {
  .mobileText {
    display: none;
  }
}
.Forms_links {
  font-size: 1.15rem;
  font-weight: bold;
  text-decoration: none;
  color: black;
}
.Forms_links:hover {
  text-decoration: underline;
  color: black;
}
.notfoundPage {
  background-color: rgb(47, 85, 151) !important;
  color: white;
}
