.Marquee_Row {
  margin-left: 0;
  margin-right: 0;
  height: 45px;
}
.Marquee_Icon {
  height: 100%;
  padding-top: 0.8rem;
  color: white;
  font-size: 0.9rem;
  padding-left: 5px;
  font-weight: bold;
  background-color: orange;
  clip-path: polygon(0% 0%, 100% 0%, 91% 50%, 100% 100%, 0% 100%);
}

.Marquee_Margins {
  margin-left: 0;
  padding-left: 0;
}
.Marquee_newsLinks {
  color: black;
  margin-right: 100px;
}
.Marquee_newsLinks:hover {
  color: black;
}
.Marquee_newsLinks::before {
  content: "";
  margin-right: 7px;
  display: inline-block;
  width: 8px;
  height: 8px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-color: orange;
}

/*  Small devices */
@media (max-width: 576px) {
  .Marquee_Icon {
    height: 100%;
    padding-top: 10px;
    color: white;
    font-size: 10px;
    padding-left: 0px;
    padding-right: 5px;
    font-weight: bold;
    background-color: orange;
    clip-path: polygon(0% 0%, 100% 0%, 91% 50%, 100% 100%, 0% 100%);
  }
}
/*  Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 768px) {
  .Marquee_Icon {
    height: 100%;
    padding-top: 10px;
    color: white;
    font-size: 10px;
    padding-left: 0px;
    padding-right: 5px;
    font-weight: bold;
    background-color: orange;
    clip-path: polygon(0% 0%, 100% 0%, 91% 50%, 100% 100%, 0% 100%);
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1023px) {
  .Marquee_Icon {
    height: 100%;
    padding-top: 15px;
    color: white;
    font-size: 10px;
    padding-left: 5px;
    font-weight: bold;
    background-color: orange;
    clip-path: polygon(0% 0%, 100% 0%, 91% 50%, 100% 100%, 0% 100%);
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 1024px) and (max-width: 1200px) {
  .Marquee_Icon {
    height: 100%;
    padding-top: 15px;
    color: white;
    font-size: 11px;
    padding-left: 5px;
    font-weight: bold;
    background-color: orange;
    clip-path: polygon(0% 0%, 100% 0%, 91% 50%, 100% 100%, 0% 100%);
  }
}

@media (min-width: 1200px) {
  .Marquee_Icon {
    height: 100%;
    padding-top: 15px;
    color: white;
    font-size: 11px;
    padding-left: 5px;
    font-weight: bold;
    background-color: orange;
    clip-path: polygon(0% 0%, 100% 0%, 91% 50%, 100% 100%, 0% 100%);
  }
}
