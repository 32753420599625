.PlanPage_row {
  margin-left: 0;
  margin-right: 0;
}

.PlanPage_header {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-color: rgb(63, 72, 204) !important;
}
.PLanPage_headerText {
  color: white;
  font-size: 22px;
  font-weight: bold;
}
.PlanPage_formDiv {
  background-color: rgb(248, 248, 250);
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 100px;
  padding-top: 20px;
  border: 0.5px solid rgb(180, 179, 179);
  box-shadow: 0 5px 5px 0 rgb(0 0 0/30%), 0 0 0 5px rgb(0 0 0 / 5%);
  margin-bottom: 50px;
}
.Plan_termText {
  font-size: 12px;
}
.PlanPage_passwordText {
  font-size: 13px;
}
.PlanPage_gobackBtn {
  border-radius: 20px;
  border: 3px solid rgb(63, 72, 204);
  color: rgb(63, 72, 204);
  background-color: transparent;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: bold;
}
.PlanPage_BackGround {
  background-repeat: no-repeat;
  background-size: cover;
}
.logo_image_plan {
  float: left;
  width: 150px;
  height: 40px;
}
.Plan_Pbtn {
  font-weight: bold;
  color: blue;
  text-decoration: underline;
  border: none;
  background-color: transparent;
}
.PlanPage_payBtn {
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: bold;
  border: 1px solid rgb(63, 72, 204);
  background-color: rgb(63, 72, 204);
  border-radius: 20px;
}
.PlanPage_successMessage {
  color: green;
  font-weight: bold;
}
/* Small Devices */
@media (min-width: 576px) and (max-width: 767px) {
  .PlanPage_formDiv {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .PlanPage_label {
    font-size: 14px;
  }
  .PlanPage_BackGround {
    background-size: 100vw;
    background-position: bottom;
  }
  .logo_image_plan {
    float: left;
    width: 50px;
    height: 25px;
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1023px) {
  .logo_image_plan {
    float: left;
    width: 100px;
    height: 30px;
  }
}
/* Small Devices */
@media (max-width: 576px) {
  .PlanPage_formDiv {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .logo_image_plan {
    float: left;
    width: 60px;
    height: 20px;
  }
  .PlanPage_label {
    font-size: 14px;
  }
  .PlanPage_BackGround {
    background-size: 100vw;
    background-position: bottom;
  }
}
