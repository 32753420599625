.AdmissionConsultingHomepage_row {
  margin-left: 0;
  margin-right: 0;
  margin-top: 40px;
}

.AdmissionConsultingHomepage_headerText {
  color: rgb(12, 12, 98);
  font-weight: bold;
  font-size: 28px;
}
.AdmissionConsultingHomepage_headerText:after {
  content: "";
  height: 3px;
  width: 10%;
  background-color: rgb(12, 12, 98);
  position: absolute;
  top: 3rem;
  left: 50%;
  transform: translate(-50%);
}
.AdmissionConsultingHomepage_card {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 25px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  border: 0.5px solid rgb(180, 179, 179);
  box-shadow: 0 5px 5px 0 rgb(0 0 0/30%), 0 0 0 5px rgb(0 0 0 / 5%);
}
.AdmissionConsultingHomepage_subheaderText {
  margin-top: 30px;
  margin-left: 90px;
  margin-right: 90px;
}
.AdmissionConsultingHomepage_cardHeading {
  font-size: 18px;
  font-weight: bold;
}
.AdmissionConsultingHomepage_cardSubHeading {
  font-size: 14px;
}
.AdmissionConsultingHomepage_icon {
  height: 100px;
  width: 100px;
}
.AdmissionConsultingHomepage_kmButton {
  margin-top: 50px;
  border: 0;
  background-color: rgb(103, 61, 230);
  color: white;
  font-weight: bold;
  padding-top: 7px;
  padding-left: 20px;
  padding-bottom: 7px;
  padding-right: 20px;
  border-radius: 20px;
}

/* ////////////////////////// */

/*  Small devices */
@media (max-width: 576px) {
  .AdmissionConsultingHomepage_row {
    margin-top: 30px;
  }
  .AdmissionConsultingHomepage_headerText {
    color: rgb(12, 12, 98);
    font-weight: bold;
    font-size: 20px;
  }
  .AdmissionConsultingHomepage_headerText:after {
    content: "";
    height: 3px;
    width: 20%;
    background-color: rgb(12, 12, 98);
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translate(-50%);
  }
  .AdmissionConsultingHomepage_subheaderText {
    margin-top: 20px;
    margin-left: 0px;
    font-size: 12px;
    margin-right: 0px;
  }
  .AdmissionConsultingHomepage_cardHeading {
    font-size: 12px;
  }
  .AdmissionConsultingHomepage_cardSubHeading {
    font-size: 10px;
  }
  .AdmissionConsultingHomepage_icon {
    height: 80px;
    width: 80px;
  }
}
/*  Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
  .AdmissionConsultingHomepage_row {
    margin-top: 30px;
  }
  .AdmissionConsultingHomepage_headerText {
    color: rgb(12, 12, 98);
    font-weight: bold;
    font-size: 20px;
  }
  .AdmissionConsultingHomepage_headerText:after {
    content: "";
    height: 3px;
    width: 20%;
    background-color: rgb(12, 12, 98);
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translate(-50%);
  }

  .AdmissionConsultingHomepage_subheaderText {
    margin-top: 20px;
    margin-left: 0px;
    font-size: 12px;
    margin-right: 0px;
  }
  .AdmissionConsultingHomepage_cardHeading {
    font-size: 12px;
  }
  .AdmissionConsultingHomepage_cardSubHeading {
    font-size: 10px;
  }
  .AdmissionConsultingHomepage_icon {
    height: 80px;
    width: 80px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1024px) {
  .AdmissionConsultingHomepage_subheaderText {
    margin-top: 20px;
    margin-left: 0px;
    font-size: 12px;
    margin-right: 0px;
  }
  .AdmissionConsultingHomepage_cardHeading {
    font-size: 14px;
  }
  .AdmissionConsultingHomepage_cardSubHeading {
    font-size: 12px;
  }
  .AdmissionConsultingHomepage_card {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    border: 0.5px solid rgb(180, 179, 179);
    box-shadow: 0 5px 5px 0 rgb(0 0 0/30%), 0 0 0 5px rgb(0 0 0 / 5%);
  }
}
/* Large devices (desktops, and up) */
@media (min-width: 1025px) and (max-width: 1200px) {
}
