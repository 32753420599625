.Footer_text {
  text-align: center;
  color: white;
  font-size: 12px;
  margin-top: 1rem;
}

.Footer_Div {
  margin-left: 0;
  margin-right: 0;
  background-color: rgb(47, 85, 151) !important;
}

.Footer_btn {
  border: none;
  color: white;
  background-color: transparent;
}
.Footer_btn:hover {
  text-decoration: underline;
}

/* ////////////////////////// */

/*  Small devices */
@media (max-width: 576px) {
  .Footer_text {
    font-size: 8px;
  }
}
/*  Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
  .Footer_text {
    font-size: 8px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1024px) {
}
/* Large devices (desktops, and up) */
@media (min-width: 1025px) and (max-width: 1200px) {
}
