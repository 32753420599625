.JeeForm_headerText {
  font-weight: bold;
  color: rgb(12, 12, 98);
  font-size: 22px;
}
.JeeForm_row {
  margin-left: 0;
  margin-right: 0;
}
.JeeForm_formCol {
  border-left: 1px solid black;
}

.JeeForm_labels {
  font-size: 15px;
  margin-bottom: 3px;
  font-weight: bold;
}

.JeeResult_tableHeader {
  text-align: left;
  position: sticky;
  top: 0;
  background-color: rgb(47, 85, 151);
  color: white;
  font-weight: bold;
}
.JeeResult_tableBody {
  text-align: left;
  background-color: rgb(249, 249, 255);
}
.Form_messageBorder {
  border: 2px solid rgb(177, 177, 177);
  padding-top: 5px;
  padding-right: 0;
  padding-left: 0;
}
.KnowMore_Btn {
  border: 0;
  background-color: rgb(103, 61, 230);
  color: white;
  font-weight: bold;
  padding-top: 5px;
  padding-left: 15px;
  padding-bottom: 5px;
  padding-right: 15px;
  border-radius: 20px;
}
.JeeForm_cImg {
  height: 25px;
  width: 25px;
  /* margin-right: 5px; */
}
.JeeForm_btnC {
  background-color: transparent;
  color: #147cb8;
  padding-left: 0;
  border: none;
  margin-right: 10px;
}
.JeeForm_btnClear {
  /* padding-left: 15px; */
  /* padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px; */
  border-radius: 5px;
  border: 2px solid #147cb8;
}
.JeeForm_btnPR {
  border-radius: 5px;
  border: 2px solid #147cb8;
  background-color: #147cb8;
}
.JeeForm_btnPRtext {
  background-color: transparent;
  padding-left: 0;
  border: none;
  color: white;
  margin-left: 5px;
  text-align: center;
}
.JeeForm_PRImg {
  height: 35px;
  width: 35px;
}
.JeeForm_btn {
  border: none;
  background-color: transparent;
}

/*  Small devices */
@media (max-width: 576px) {
  .JeeForm_formCol {
    border: none;
  }
  .JeeForm_formCol {
    border: none;
  }
  .JeeForm_btnPRtext {
    margin-right: 10px;
    font-size: 14px;
  }
  .JeeForm_btnC {
    font-size: 14px;
  }
  .JeeResult_tableHeader {
    font-size: 14px;
  }
  .JeeResult_tableBody {
    font-size: 12px;
  }
  .Jee_subText {
    font-size: 12px;
  }
  .KnowMore_Btn {
    border: 0;
    background-color: rgb(103, 61, 230);
    color: white;
    font-weight: bold;
    padding-top: 5px;
    padding-left: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    border-radius: 20px;
    font-size: 10px;
    width: 80px;
  }
}
/*  Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
  .KnowMore_Btn {
    border: 0;
    background-color: rgb(103, 61, 230);
    color: white;
    font-weight: bold;
    padding-top: 5px;
    padding-left: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    border-radius: 20px;
    font-size: 10px;
    width: 80px;
  }
  .Jee_subText {
    font-size: 12px;
  }
  .JeeForm_formCol {
    border: none;
  }
  .JeeForm_formCol {
    border: none;
  }
  .JeeForm_btnPRtext {
    margin-right: 10px;
    font-size: 14px;
  }
  .JeeForm_btnC {
    font-size: 14px;
  }
  .JeeResult_tableHeader {
    font-size: 14px;
  }
  .JeeResult_tableBody {
    font-size: 12px;
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1023px) {
  .JeeForm_btnC {
    font-size: 14px;
    /* margin-right: 10; */
  }
  .JeeForm_btnPRtext {
    font-size: 14px;
    margin-right: 30px;
  }
  .KnowMore_Btn {
    border: 0;
    background-color: rgb(103, 61, 230);
    color: white;
    font-weight: bold;
    padding-top: 5px;
    padding-left: 10px;
    padding-bottom: 5px;
    padding-right: 10px;
    border-radius: 20px;
    font-size: 12px;
    width: 90px;
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 1024px) and (max-width: 1200px) {
}
