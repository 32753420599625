.CetForm_headerText {
  font-weight: bold;
  color: rgb(12, 12, 98);
  line-height: 1.4;
  font-size: 20px;
  text-align: left;
}
.CetForm_row {
  margin-left: 0;
  margin-right: 0;
}
.CetForm_formCol {
  border-left: 1px solid black;
}
.CetForm_labels {
  font-size: 15px;
  margin-bottom: 3px;
  font-weight: bold;
}
.CetResult_tableHeader {
  text-align: left;
  position: sticky;
  top: 0;
  background-color: rgb(47, 85, 151);
  color: white;
  font-weight: bold;
}
.CetResult_tableBody {
  text-align: left;
  background-color: rgb(249, 249, 255);
}

.CetForm_cImg {
  height: 25px;
  width: 25px;
  /* margin-right: 5px; */
}
.CetForm_btnC {
  background-color: transparent;
  color: #147cb8;
  padding-left: 0;
  border: none;
}
.CetForm_btnClear {
  /* padding-left: 15px; */
  /* padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px; */
  border-radius: 5px;
  border: 2px solid #147cb8;
}
.CetForm_btnPR {
  border-radius: 5px;
  border: 2px solid #147cb8;
  background-color: #147cb8;
}
.CetForm_btnPRtext {
  background-color: transparent;
  padding-left: 0;
  border: none;
  color: white;
  margin-left: 5px;

  margin-right: 10px;
}
.CetForm_PRImg {
  height: 35px;
  width: 35px;
}
.CetForm_btn {
  border: none;
  background-color: transparent;
}

/*  Small devices */
@media (max-width: 576px) {
  .Cet_subText {
    font-size: 12px;
  }
  .CetForm_formCol {
    border: none;
  }
  .CetForm_btnPRtext {
    font-size: 14px;
  }
  .CetForm_btnC {
    font-size: 14px;
  }
  .CetResult_tableHeader {
    font-size: 14px;
  }
  .CetResult_tableBody {
    font-size: 12px;
  }
}
/*  Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
  .Cet_subText {
    font-size: 12px;
  }
  .CetForm_formCol {
    border: none;
  }
  .CetForm_btnPRtext {
    font-size: 14px;
  }
  .CetForm_btnC {
    font-size: 14px;
  }
  .CetResult_tableHeader {
    font-size: 14px;
  }
  .CetResult_tableBody {
    font-size: 12px;
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1023px) {
  .CetForm_btnC {
    font-size: 14px;
    /* margin-right: 10; */
  }
  .CetForm_btnPRtext {
    font-size: 14px;
    margin-right: 30px;
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 1024px) and (max-width: 1200px) {
}
