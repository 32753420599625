.News_headerText {
  margin-top: 15px;
  color: rgb(12, 12, 98);
  font-weight: bold;
  font-size: 28px;
}
.News_headerText:after {
  content: "";
  height: 3px;
  width: 3.5%;
  background-color: rgb(12, 12, 98);
  position: absolute;
  top: 3.7rem;
  left: 50%;
  transform: translate(-50%);
}
.News_subheaderText {
  margin-top: 20px;
  /* font-size: 20px; */
}
.News_header {
  margin-left: 0;
  margin-right: 0;
  margin-top: 40px;
}
.News_headerTable {
  margin-left: 0;
  margin-right: 0;
}
.News_Table {
  overflow-y: scroll;
  max-height: 300px !important;
  /* height: 300px !important; */
}
.News_Table::-webkit-scrollbar {
  width: 10px;
}

.News_Table::-webkit-scrollbar-track {
  background: grey; /* color of the tracking area */
}

.News_Table::-webkit-scrollbar-thumb {
  background-color: black; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid black; /* creates padding around scroll thumb */
}
.News_tableHeader {
  position: sticky;
  text-align: left;
  top: 0;
  background-color: rgb(47, 85, 151);
  color: white;
  font-weight: bold;
}
.News_tableBody {
  text-align: left;
  background-color: rgb(249, 249, 255);
}
.News_newsTitle {
  color: black;
  display: -webkit-box;
  /* -webkit-line-clamp: 1;
  height: 2rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; */
}
.News_newsTitle:hover {
  color: black;
  text-decoration-color: black;
}
/* ////////////////////////// */

/*  Small devices */
@media (max-width: 576px) {
  .News_headerText {
    margin-top: 15px;
    color: rgb(12, 12, 98);
    font-weight: bold;
    font-size: 20px;
  }
  .News_headerText:after {
    content: "";
    height: 3px;
    width: 5%;
    background-color: rgb(12, 12, 98);
    position: absolute;
    top: 3rem;
    left: 50%;
    transform: translate(-50%);
  }
  .News_subheaderText {
    margin-top: 20px;
    font-size: 12px;
  }
  .News_tableHeader {
    font-size: 16px;
  }
  .News_tableBody {
    font-size: 14px;
  }
  .News_newsTitle {
    color: black;
    display: -webkit-box;
    /* -webkit-line-clamp: 1;
    height: 1rem;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; */
  }
  .News_Table::-webkit-scrollbar {
    width: 5px;
  }
  .News_header {
    margin-top: 30px;
  }
}
/*  Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
  .News_header {
    margin-top: 30px;
  }

  .News_headerText {
    margin-top: 15px;
    color: rgb(12, 12, 98);
    font-weight: bold;
    font-size: 20px;
  }
  .News_headerText:after {
    content: "";
    height: 3px;
    width: 5%;
    background-color: rgb(12, 12, 98);
    position: absolute;
    top: 3rem;
    left: 50%;
    transform: translate(-50%);
  }
  .News_subheaderText {
    margin-top: 20px;
    font-size: 12px;
  }
  .News_tableHeader {
    font-size: 16px;
  }
  .News_tableBody {
    font-size: 14px;
  }
  .News_newsTitle {
    color: black;
    display: -webkit-box;
    /* -webkit-line-clamp: 2;
    height: 1rem;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; */
  }
  .News_Table::-webkit-scrollbar {
    width: 5px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1024px) {
}
/* Large devices (desktops, and up) */
@media (min-width: 1025px) and (max-width: 1200px) {
}
