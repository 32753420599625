.ContactUs_row {
  margin-left: 0;
  margin-right: 0;
}

.ContactUs_headerText {
  color: rgb(12, 12, 98);
  font-weight: bold;
  font-size: 28px;
}
.ContactUs_headerText:after {
  content: "";
  height: 3px;
  width: 10%;
  background-color: rgb(12, 12, 98);
  position: absolute;
  top: 3rem;
  left: 50%;
  transform: translate(-50%);
}

.ContactUs_subheaderText {
  margin-top: 30px;
  margin-left: 200px;
  margin-right: 200px;
}
.ContactUs_icons:hover {
  transform: scale(1.2);
  /* text-shadow: 10px 10px 10px 10px grey; */
}

.ContactUs_Margins {
  margin-top: 40px;
}
/* ////////////////////////// */

/*  Small devices */
@media (max-width: 576px) {
  .MobileViewHide {
    display: none !important;
  }

  .MobileViewShow {
    display: block !important;
  }
  .ContactUs_icons {
    width: 2rem;
    height: 2rem;
  }
  .AboutUs_linksText {
    font-size: 12px;
  }
  .ContactUs_headerText {
    color: rgb(12, 12, 98);
    font-weight: bold;
    font-size: 20px;
  }
  .ContactUs_headerText:after {
    content: "";
    height: 3px;
    width: 20%;
    background-color: rgb(12, 12, 98);
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translate(-50%);
  }

  .ContactUs_subheaderText {
    margin-top: 20px;
    margin-right: 0px;
    margin-left: 0px;
    font-size: 12px;
  }
  .ContactUs_icons:hover {
    transform: scale(1.2);
    /* text-shadow: 10px 10px 10px 10px grey; */
  }

  .ContactUs_Margins {
    margin-top: 30px;
  }
  .ContactUs_icons {
    size: 2rem !important;
  }
}
/*  Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
  .ContactUs_icons {
    width: 2rem;
    height: 2rem;
  }
  .AboutUs_linksText {
    font-size: 14px;
  }
  .MobileViewHide {
    display: none !important;
  }

  .MobileViewShow {
    display: block !important;
  }
  .ContactUs_Margins {
    margin-top: 30px;
  }
  .ContactUs_headerText {
    color: rgb(12, 12, 98);
    font-weight: bold;
    font-size: 20px;
  }
  .ContactUs_headerText:after {
    content: "";
    height: 3px;
    width: 20%;
    background-color: rgb(12, 12, 98);
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translate(-50%);
  }

  .ContactUs_subheaderText {
    margin-top: 20px;
    margin-right: 0px;
    margin-left: 0px;
    font-size: 12px;
  }
  .ContactUs_icons:hover {
    transform: scale(1.2);
    /* text-shadow: 10px 10px 10px 10px grey; */
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1024px) {
  .ContactUs_subheaderText {
    margin-top: 20px;
    margin-right: 0px;
    margin-left: 0px;
    font-size: 12px;
  }
}
/* Large devices (desktops, and up) */
@media (min-width: 1025px) and (max-width: 1200px) {
}
.MobileViewHide {
  display: block;
}

.MobileViewShow {
  display: none;
}
.ContactUs_icons {
  width: 3rem;
  height: 3rem;
}
