.AdmissionConsultingPage_row {
  margin-left: 0;
  margin-right: 0;
  margin-top: 40px;
}
.AdmissionConsultingPage_headerText {
  font-weight: bold;
  color: rgb(12, 12, 98);
}
.AdmissionConsultingPage_pointRow {
  border: 2px solid black;
}
.AdmissionConsultingPage_headerTextSub {
  font-weight: bold;
  color: rgb(12, 12, 98);
  font-size: 22px;
}
.AdmissionConsultingPage_pointText {
  font-size: 18px;
}
.AdmissionConsultingPage_rowMargin {
  margin-left: 0;
  margin-right: 0;
}
.AdmissionConsultingPage_Card {
  border-radius: 10px;
  border: 0.5px solid rgb(180, 179, 179);
  box-shadow: 0 5px 5px 0 rgb(0 0 0/30%), 0 0 0 5px rgb(0 0 0 / 5%);
}
.AdmissionConsulting_StrikedText {
  color: grey;
  font-size: 18px;
  margin-bottom: 0px;
}
.AdmissionConsultingPage_Footer {
  margin-bottom: 50px;
  margin-top: 0;
}
.AdmissionConsulting_instalink {
  color: rgb(247, 37, 132);
  font-weight: bold;
}
.AdmissionConsulting_instalink:hover {
  color: rgb(247, 37, 132);
  font-weight: bold;
  text-decoration: underline;
}
.AdmissionConsult_ExtraMargin {
  margin-top: 35px;
}
.AdmissionConsultingPage_cardHeader {
  font-weight: bold;
}

.AdmissionConsultingPage_opHeader {
  color: rgb(63, 72, 204);
  font-weight: bold;
}
.AdmissionConsultingPage_cardHeader {
  color: rgb(12, 12, 98);
}
.AdmissionConsultingPage_amtText {
  color: rgb(12, 12, 98);
  font-weight: bold;
}
.AdmissionConsultingPage_termsText {
  font-size: 14px;
  margin-bottom: 25px;
}
/* Small Devices */
@media (min-width: 576px) and (max-width: 767px) {
  .AdmissionConsultingPage_headerText {
    font-size: 18px;
  }
  .AdmissionConsultingPage_opHeader {
    font-size: 18px;
  }
  .AdmissionConsultingPage_pointText {
    font-size: 16px;
  }
  .AdmissionConsultingPage_Footer {
    font-size: 12px;
  }
  .AdmissionConsultingPage_termsText {
    font-size: 10px;
    margin-bottom: 10px;
  }
}
/* Small Devices */
@media (max-width: 576px) {
  .AdmissionConsultingPage_headerText {
    font-size: 16px;
  }
  .AdmissionConsultingPage_opHeader {
    font-size: 18px;
  }
  .AdmissionConsultingPage_pointText {
    font-size: 16px;
  }
  .AdmissionConsultingPage_Footer {
    font-size: 12px;
  }
  .AdmissionConsultingPage_termsText {
    font-size: 10px;
    margin-bottom: 10px;
  }
}
