.SuccessPage_row {
  margin-left: 0;
  margin-right: 0;
}
.SuccessPage_header {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-color: rgb(47, 85, 151) !important;
}
.SuccessPage_headerText {
  color: white;
  font-size: 22px;
  font-weight: bold;
}

.SuccessPage_subDiv {
  background-color: rgb(248, 248, 250);
  padding-bottom: 30px;
  font-size: 18px;
}

.SuccessPage_text {
  color: green;
  font-weight: bold;
  padding-top: 10px;
}

.SuccessPage_links {
  font-size: 17px;
  font-weight: bold;
  text-decoration: underline;
}
