.Navbar_MainDiv {
  /* height: 100px; */
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: rgb(47, 85, 151) !important;
}

.Navbar_Logo {
  font-size: 25px;
  margin-left: 50px;
  color: white !important;
  font-weight: bold;
}
.Navbar_RightDiv {
  margin-right: 100px;
  float: right;
}

.Navbar_TextColor {
  font-size: 19px;
  color: white !important;
}
.Navbar_TextColor:hover {
  text-decoration: underline;
}
.active {
  font-weight: bold;
}
.logo_image {
  float: left;
  width: 169px;
  height: 43px;
}
/*  Small devices  */
@media (max-width: 576px) {
  .logo_image {
    width: 104px;
    height: 30px;
  }
  .Navbar_RightDiv {
    margin-right: 0px;
    float: right;
    text-align: right;
  }

  .Navbar_Logo {
    margin-left: 5px;
    color: white !important;
    font-weight: bold;
  }
}

/*  Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 768px) {
  .Navbar_RightDiv {
    margin-right: 0px;
    float: right;
  }

  .Navbar_Logo {
    margin-left: 5px;
    color: white !important;
    font-weight: bold;
  }
  .logo_image {
    width: 104px;
    height: 30px;
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1023px) {
  .logo_image {
    width: 132px;
    height: 34px;
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 1024px) and (max-width: 1200px) {
  .Navbar_RightDiv {
    margin-right: 0px;
    float: right;
  }
}
