.ModalPP_content {
  margin: auto;
  margin-top: 100px;
  text-align: center;
  background-color: white;
  height: 80%;
  width: 70%;
  border: 1px solid black;
  justify-self: center;
  overflow-y: scroll;
}
.ModalMargin_top {
  margin-top: 10px;
}

.ModalForm_content {
  margin: auto;
  margin-top: 100px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  background-color: white;
  height: 40%;
  width: 60%;
  border: 1px solid black;
  justify-self: center;
}
.Modal_row {
  margin-left: 0;
  margin-right: 0;
}
.ModelPP_closeBtn {
  background-color: transparent;
  border: none;
}
.ModelPP_closeImg {
  height: 30px;
  width: 30px;
  padding: 2px;
}
.ModalPara_div {
  margin-left: 20px;
  margin-right: 20px;
}
.ModelPP_header {
  color: rgb(12, 12, 98);
  font-weight: bold;
  font-size: 22px;
}

/* --------------------------------------------------------------------------------------- */
.ModalT_content {
  margin: auto;
  margin-top: 100px;
  /* margin-right: -50%; */
  text-align: center;
  background-color: white;
  height: 80%;
  width: 70%;
  border: 1px solid black;
  justify-self: center;
  overflow-y: scroll;
  /* overflow: hidden; */
}
.ModelT_closeBtn {
  background-color: transparent;
  border: none;
}
.ModelT_closeImg {
  height: 30px;
  width: 30px;
  padding: 2px;
}
.Modal_ol {
  list-style: none;
}
.ModelT_header {
  color: rgb(12, 12, 98);
  font-weight: bold;
  font-size: 22px;
}

/*  Small devices  */
@media (max-width: 576px) {
  .Navbar_RightDiv {
    margin-right: 0px;
    float: right;
    text-align: right;
  }

  .Navbar_Logo {
    margin-left: 5px;
    color: white !important;
    font-weight: bold;
  }
  .ModalForm_content {
    height: 60%;
    width: 78%;
    font-size: 14px;
  }
}
@media (max-width: 576px) {
  .ModalPP_content {
    margin: auto;
    margin-top: 100px;
    /* margin-right: -50%; */
    text-align: center;
    background-color: white;
    height: 50%;
    width: 70%;
    border: 1px solid black;
    justify-self: center;
    font-size: 12px;
    /* overflow: hidden; */
  }
  .ModalT_content {
    margin: auto;
    margin-top: 100px;
    /* margin-right: -50%; */
    text-align: center;
    background-color: white;
    height: 50%;
    width: 70%;
    font-size: 12px;
    border: 1px solid black;
    justify-self: center;
  }
  .ModelPP_header {
    color: rgb(12, 12, 98);
    font-weight: bold;
    font-size: 20px;
  }
  .ModelT_header {
    font-size: 20px;
  }
  .ModalPara_div {
    margin-left: 10px;
    margin-right: 10px;
  }
}

/*  Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
  .ModelPP_header {
    color: rgb(12, 12, 98);
    font-weight: bold;
    font-size: 20px;
  }
  .ModalPP_content {
    margin: auto;
    margin-top: 100px;
    /* margin-right: -50%; */
    text-align: center;
    background-color: white;
    height: 50%;
    width: 70%;
    border: 1px solid black;
    justify-self: center;
    font-size: 12px;
    /* overflow: hidden; */
  }
  .ModalPara_div {
    margin-left: 10px;
    margin-right: 10px;
  }
  .ModalForm_content {
    height: 60%;
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1023px) {
  .ModalForm_content {
    height: 50%;
  }
}
/* Large devices (desktops, and up) */
@media (min-width: 1024px) and (max-width: 1200px) {
  .ModalForm_content {
    height: 50%;
  }
}
