.HomePage_secondRow {
  margin-left: 0;
  margin-right: 0;
  margin-top: 50px;
}
.HomePage_pHeader {
  /* font-family: "Poppins"; */
  font-weight: bold;
  color: rgb(12, 12, 98);
  /* line-height: 1.4; */
  /* font-size: 40px; */
  text-align: left;
}
.HomePage_pSubHeader {
  /* font-family: "Poppins"; */
  margin-top: 30px;
  text-align: left;
  color: rgb(12, 12, 98);
  /* font-size: 20px; */
  padding-right: 30px;
}

/*  Small devices */
@media (max-width: 576px) {
  .HomePage_pHeader {
    /* font-family: "Poppins"; */
    font-weight: bold;
    color: rgb(12, 12, 98);
    font-size: 25px;
    text-align: left;
  }

  .HomePage_pSubHeader {
    /* font-family: "Poppins"; */
    margin-top: 10px;
    text-align: left;
    color: rgb(12, 12, 98);
    font-size: 16px;
    padding-right: 0;
  }
  .HomePage_graphic {
    height: 200px;
    width: 300px;
  }
  .HomePage_secondRow {
    margin-top: 30px;
  }
}
/*  Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) and (max-width: 767px) {
  .HomePage_secondRow {
    margin-top: 30px;
  }
  .HomePage_pHeader {
    /* font-family: "Poppins"; */
    font-weight: bold;
    color: rgb(12, 12, 98);
    font-size: 25px;
    padding-right: 0px;
    text-align: left;
  }
  .HomePage_col {
    padding-left: 0;
  }
  .HomePage_pSubHeader {
    /* font-family: "Poppins"; */
    margin-top: 10px;
    text-align: left;
    color: rgb(12, 12, 98);
    font-size: 16px;
  }
  .HomePage_graphic {
    height: 200px;
    width: 300px;
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1023px) {
  .HomePage_pHeader {
    /* font-family: "Poppins"; */
    font-weight: bold;
    color: rgb(12, 12, 98);
    font-size: 20px;
    text-align: left;
  }
  .HomePage_col {
    padding-left: 0;
  }
  .HomePage_pSubHeader {
    /* font-family: "Poppins"; */
    margin-top: 10px;
    text-align: left;
    color: rgb(12, 12, 98);
    font-size: 14px;
    padding-right: 5px;
  }
  .HomePage_graphic {
    height: 200px;
    width: 300px;
  }
}
/* Large devices (desktops, and up) */
@media (min-width: 1024px) and (max-width: 1200px) {
  .Marquee_Icon {
    height: 100%;
    padding-top: 15px;
    color: white;
    font-size: 11px;
    padding-left: 5px;
    font-weight: bold;
    background-color: orange;
    clip-path: polygon(0% 0%, 100% 0%, 91% 50%, 100% 100%, 0% 100%);
  }
  .HomePage_pSubHeader {
    /* font-family: "Poppins"; */
    margin-top: 10px;
    text-align: left;
    color: rgb(12, 12, 98);
    font-size: 16px;
    padding-right: 5px;
  }
  .HomePage_graphic {
    height: 250px;
    width: 350px;
  }
  .HomePage_pHeader {
    /* font-family: "Poppins"; */
    font-weight: bold;
    color: rgb(12, 12, 98);
    font-size: 26px;
    text-align: left;
  }
}
